var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('h4',{staticClass:"font-size-22 text-uppercase font-weight-bold mb-4"},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"add-new-venue"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-5"},[_c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.continueToNextStep.apply(null, arguments)}}},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-md-6"},[_c('strong',[_vm._v(_vm._s(_vm.$t("Venue name")))])]),_c('div',{staticClass:"col-md-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-control",class:{ 'is-invalid': _vm.isSubmitted && _vm.$v.name.$error },attrs:{"type":"text","placeholder":_vm.$t('Venue name'),"name":"name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.name.required)?_c('span',[_vm._v(_vm._s(_vm.$t("Venue name is required.")))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-md-6",attrs:{"for":"selected-playlist"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("Select playlist")))])]),_c('div',{staticClass:"col-md-6"},[_c('v-select',{staticClass:"playlist-custom-select",class:{
                        'is-invalid':
                          _vm.isSubmitted && _vm.$v.selectedPlaylist.$error,
                      },attrs:{"label":"name","options":_vm.venueTypes,"placeholder":_vm.$t('Select playlist')},model:{value:(_vm.selectedPlaylist),callback:function ($$v) {_vm.selectedPlaylist=$$v},expression:"selectedPlaylist"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("No options.")))])]),(_vm.isSubmitted && _vm.$v.selectedPlaylist.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedPlaylist.required)?_c('span',[_vm._v(_vm._s(_vm.$t("Playlist is required.")))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-md-6"},[_c('strong',[_vm._v(_vm._s(_vm.$t("Street and descriptive house number")))])]),_c('div',{staticClass:"col-md-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.isSubmitted && _vm.$v.address.$error,
                      },attrs:{"type":"text","placeholder":_vm.$t('Street and descriptive house number'),"name":"name"},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.address=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.address.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.address.required)?_c('span',[_vm._v(_vm._s(_vm.$t("Venue's address is required.")))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-md-6"},[_c('strong',[_vm._v(_vm._s(_vm.$t("Zip code")))])]),_c('div',{staticClass:"col-md-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formatZipCode),expression:"formatZipCode"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.isSubmitted && _vm.$v.zipCode.$error,
                      },attrs:{"type":"text","placeholder":_vm.$t('Zip code'),"name":"name"},domProps:{"value":(_vm.formatZipCode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.formatZipCode=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.zipCode.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.zipCode.required)?_c('span',[_vm._v(_vm._s(_vm.$t("Zip code is required.")))]):_vm._e(),(!_vm.$v.zipCode.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t("Please enter valid zip code")))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-md-6"},[_c('strong',[_vm._v(_vm._s(_vm.$t("City")))])]),_c('div',{staticClass:"col-md-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.city),expression:"city"}],staticClass:"form-control",class:{ 'is-invalid': _vm.isSubmitted && _vm.$v.city.$error },attrs:{"type":"text","placeholder":_vm.$t('City'),"name":"name"},domProps:{"value":(_vm.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.city=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.city.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.city.required)?_c('span',[_vm._v(_vm._s(_vm.$t("City is required.")))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-md-6",attrs:{"for":"selected-country-code"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("Country")))])]),_c('div',{staticClass:"col-md-6"},[_c('v-select',{staticClass:"playlist-custom-select",class:{
                        'is-invalid':
                          _vm.isSubmitted && _vm.$v.selectedCountryCode.$error,
                      },attrs:{"label":"name","options":_vm.countryCodes,"placeholder":_vm.$t('Country')},model:{value:(_vm.selectedCountryCode),callback:function ($$v) {_vm.selectedCountryCode=$$v},expression:"selectedCountryCode"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("No options.")))])]),(_vm.isSubmitted && _vm.$v.selectedCountryCode.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedCountryCode.required)?_c('span',[_vm._v(_vm._s(_vm.$t("Country is required.")))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-md-6"},[_c('strong',[_vm._v(_vm._s(_vm.$t("GPS latitude")))])]),_c('div',{staticClass:"col-md-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.latitude),expression:"latitude"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.isSubmitted && _vm.$v.latitude.$error,
                      },attrs:{"type":"text","placeholder":_vm.$t('GPS latitude'),"name":"name"},domProps:{"value":(_vm.latitude)},on:{"change":_vm.centerMap,"input":function($event){if($event.target.composing){ return; }_vm.latitude=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.latitude.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.latitude.required)?_c('span',[_vm._v(_vm._s(_vm.$t("GPS latitude is required.")))]):_vm._e(),(!_vm.$v.latitude.decimal)?_c('span',[_vm._v(_vm._s(_vm.$t("GPS latitude must be a number.")))]):_vm._e(),(
                          !_vm.$v.latitude.checkLatitude &&
                          _vm.$v.latitude.required &&
                          _vm.$v.latitude.decimal
                        )?_c('span',[_vm._v(_vm._s(_vm.$t("Value must be between -90 and 90")))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-md-6"},[_c('strong',[_vm._v(_vm._s(_vm.$t("GPS longitude")))])]),_c('div',{staticClass:"col-md-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.longitude),expression:"longitude"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.isSubmitted && _vm.$v.longitude.$error,
                      },attrs:{"type":"text","placeholder":_vm.$t('GPS longitude'),"name":"name"},domProps:{"value":(_vm.longitude)},on:{"change":_vm.centerMap,"input":function($event){if($event.target.composing){ return; }_vm.longitude=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.longitude.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.longitude.required)?_c('span',[_vm._v(_vm._s(_vm.$t("GPS longitude is required.")))]):_vm._e(),(!_vm.$v.longitude.decimal)?_c('span',[_vm._v(_vm._s(_vm.$t("GPS longitude must be a number.")))]):_vm._e(),(
                          !_vm.$v.longitude.checkLongitude &&
                          _vm.$v.longitude.required &&
                          _vm.$v.longitude.decimal
                        )?_c('span',[_vm._v(_vm._s(_vm.$t("Value must be between -180 and 180")))]):_vm._e()]):_vm._e()])])]),_c('b-col',[_c('div',{ref:"map",staticClass:"map",attrs:{"id":"map"}})])],1),_c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('b-button',{staticClass:"text-uppercase",staticStyle:{"padding":"10px 20px","background":"#eba721","border-radius":"15px"},attrs:{"variant":"warning"},on:{"click":_vm.continueToNextStep}},[_vm._v(" "+_vm._s(_vm.$t("Next step"))+" ")])],1)],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }