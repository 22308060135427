






















































































































































































































































































import Vue from "vue";
import axios from "axios";
import Layout from "@/router/layouts/main.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { required, decimal, maxLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { VenueRegisterData, TimezoneData } from "@/types";
import Scriptjs from "scriptjs";
import debounce from "lodash/debounce";

declare const window: any;

export default Vue.extend({
  components: {
    Layout,
    vSelect,
  },

  data() {
    return {
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      autocomplete: null,
      map: null,
      emptyResults: false,

      title: "Add New Venue",
      name: "",
      address: "",
      zipCode: "",
      latitude: 48.1486,
      longitude: 17.1077,
      // latitude: null,
      // longitude: null,
      city: "",
      selectedPlaylist: null,
      selectedCountryCode: null,
      show: true,
      isSubmitted: false,
    };
  },

  async mounted(): Promise<void> {
    await this.setVenueTypes(true);
    await this.setCountryCode();
    await this.setTimezones();
    this.createGoogleMapsScript();
  },

  //@ts-ignore
  validations: {
    name: { required },
    address: { required },
    zipCode: { required, maxLength: maxLength(10) },
    latitude: {
      required,
      decimal,
      checkLatitude(latitude) {
        let latitudeRegex = new RegExp(
          "^(\\+|-)?(?:90(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,30})?))$"
        );
        return latitudeRegex.test(latitude);
      },
    },
    longitude: {
      required,
      decimal,
      checkLongitude(longitude) {
        let longitudeRegex = new RegExp(
          "^(\\+|-)?(?:180(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,30})?))$"
        );
        return longitudeRegex.test(longitude);
      },
    },
    city: { required },
    selectedCountryCode: { required },
    selectedPlaylist: { required },
  },

  computed: {
    ...mapGetters("venueModule", {
      newVenueSubPlan: "GET_NEW_VENUE_SUB_PLAN",
      newVenueId: "GET_NEW_VENUE_ID",
      countryCodes: "GET_COUNTRY_CODES",
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID",
    }),
    ...mapGetters("playlistModule", {
      venueTypes: "GET_VENUE_TYPES",
    }),
    ...mapGetters("globalModule", {
      timezones: "GET_TIMEZONES",
    }),
    timezoneId(): number {
      return this.timezones.find(
        t => t.country_code.toLowerCase() === this.selectedCountryCode.code
      ).id;
    },
    formatZipCode: {
      get() {
        return this.zipCode;
      },
      set(value: any) {
        this.zipCode = value.toUpperCase();
      },
    },
    getFormattedAddress() {
      var address = "";
      if (this.address) {
        address += this.address;
      } else {
        return null;
      }
      if (this.zipCode) {
        address += "," + this.zipCode;
      }
      if (this.city) {
        address += "," + this.city;
      }
      return address;
    },
  },

  methods: {
    ...mapActions("venueModule", {
      setCountryCode: "SET_COUNTRY_CODES",
      setVenueRegisterData: "SET_VENUE_REGISTER_DATA",
    }),
    ...mapActions("playlistModule", {
      setVenueTypes: "FETCH_VENUE_TYPES",
    }),
    ...mapActions("globalModule", {
      setTimezones: "FETCH_TIMEZONES",
    }),
    continueToNextStep(): void {
      this.isSubmitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const payload: VenueRegisterData = {
        name: this.name,
        address1: this.address,
        address2: this.zipCode,
        latitude: Number(this.latitude),
        longitude: Number(this.longitude),
        city: this.city,
        country_id: this.selectedCountryCode.id,
        timezone_id: this.timezoneId,
        state: 0,
        venue_owner_id: this.companyId,
      };

      if (this.selectedPlaylist.venue_type_alias_id)
        payload["venue_type_alias_id"] =
          this.selectedPlaylist.venue_type_alias_id;
      else payload["venue_type_id"] = this.selectedPlaylist.venue_type_id;

      this.setVenueRegisterData({
        payload: payload,
        countryCode: this.selectedCountryCode.code,
      });
    },
    createGoogleMapsScript() {
      const apiKey = this.apiKey;
      const lang = this.$i18n.locale;
      Scriptjs(
        `https://maps.googleapis.com/maps/api/js?key=${apiKey}&language=${lang}`,
        () => {
          let zoom = 8;
          this.googleMapsInit(zoom);
        }
      );
    },
    getLatLng() {
      return {
        lat: Number(this.latitude),
        lng: Number(this.longitude),
      };
    },
    googleMapsInit(zoom: number) {
      if (window.google) {
        const center = this.getLatLng();

        this.map = new window.google.maps.Map(document.getElementById("map"), {
          zoom: zoom || 18,
          center: center,
        });

        this.marker = new window.google.maps.Marker({
          position: center,
          map: this.map,
          draggable: true,
          title: this.address,
        });
        this.marker.addListener("dragend", () => {
          const position = this.marker.getPosition();
          var lat = position.lat(),
            lng = position.lng();

          if (String(lat).length > 12) {
            lat = lat.toFixed(9);
          }

          if (String(lng).length > 12) {
            lng = lng.toFixed(9);
          }
          this.latitude = lat;
          this.longitude = lng;
          this.centerMap();
        });
      }
    },
    searchAddress: debounce(async function () {
      const apiKey = this.apiKey;

      var address = this.getFormattedAddress;
      if (!address) {
        return false;
      }

      var url = `https://maps.googleapis.com/maps/api/geocode/json?key=${apiKey}&address=${address}`;

      var components = "&components=";
      if (this.selectedCountryCode) {
        components += "country:" + this.selectedCountryCode.code;
        url += components;
      }

      try {
        var response = await axios.get(url, {
          transformRequest: (data, headers) => {
            delete headers.common["Authorization"];
            return data;
          },
        });
        var result = response.data.results[0];
        if (result) {
          if (result.hasOwnProperty("geometry")) {
            var location = result.geometry.location;
            this.latitude = location.lat;
            this.longitude = location.lng;
            this.emptyResults = false;
            if (!this.map) {
              this.googleMapsInit();
            } else {
              this.centerMap();
            }
          } else {
            this.emptyResults = true;
          }
        } else {
          this.emptyResults = true;
        }
      } catch (e) {
        console.error(e);
      }
    }, 500),
    centerMap() {
      let center = this.getLatLng();
      this.marker.setPosition(center);
      this.map.setCenter(center);
      this.map.setZoom(18);
    },
  },
  watch: {
    address() {
      if (this.map) {
        this.searchAddress();
      }
    },
    zipCode() {
      if (this.map) {
        this.searchAddress();
      }
    },
    city() {
      if (this.map) {
        this.searchAddress();
      }
    },
    selectedCountryCode() {
      if (this.map) {
        this.searchAddress();
      }
    },
  },
});
